import React, { useEffect } from 'react';
import css from './ModalImageCarousal.module.css';
import Modal from '../Modal/Modal';
import Slider from 'react-slick';
import { ReactComponent as CustomNextArrow } from '../../assets/rightArrow.svg';
import { ReactComponent as CustomPrevArrow } from '../../assets/leftArrow.svg';
import { BiVolumeMute } from 'react-icons/bi';
import { GoUnmute } from 'react-icons/go';

const ModalImageCarousal = props => {
  const {
    isOpen,
    onManageDisableScrolling,
    imageLinks,
    setIsOpen,
    videoRef,
    toggleMute,
    handleMouseLeave,
    handleHover,
    ismuted,
  } = props; 
  const handleBeforeChange = (oldIndex, newIndex) => {
    const newSlide = imageLinks[newIndex];
    if (newSlide?.location?.includes('/videos/')) {
      videoRef.current?.play(); // Play the video when the slide changes to a video slide
    }
  };

  const settings = {
    dots: false,
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    arrows: true, 
    initialSlide: 0,
    beforeChange: handleBeforeChange,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  return (
    <Modal
      className={css.ContactUsmodal}
      id="imageModal"
      isOpen={isOpen}
      scrollLayerClassName={css.carouselModalScrollLayer}
      containerClassName={css.carouselModalContainer}
      onClose={() => {
        setIsOpen(false);
      }}
      // showAsModalMaxWidth={MODAL_BREAKPOINT}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <div>
        <Slider {...settings}>
          {imageLinks?.map((m, i) => (
            // console.log(905, imageLinks);
            <div className={css.parentContainer}>
              {m?.location?.includes('/images/') ? (
                <div className={css.imageContainer}>
                  <img src={m?.location} alt="Preview" className={css.sliderImageWrapper} />
                </div>
              ) : m?.location?.includes('/videos/') ? (
                <>
                  <div
                    className={css.imageContainer}
                    //   onMouseEnter={handleHover}
                    //   onMouseLeave={handleMouseLeave}
                  >
                    <video ref={videoRef} className={css.sliderImageWrapper} muted="muted">
                      <source src={m?.location} type="video/mp4" />
                    </video>
                    {/* <div className={css.muteButton} onClick={e => toggleMute(e)}>
                      {ismuted ? <BiVolumeMute /> : <GoUnmute />}
                    </div> */}
                  </div>
                </>
              ) : null}
            </div>
          ))}
        </Slider>
      </div>
    </Modal>
  );
};

export default ModalImageCarousal;
