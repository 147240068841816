import React, { useEffect, useMemo, useRef, useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { ReactComponent as Share } from '../../assets/Vector.svg';
import { ReactComponent as OnlineLogo } from '../../assets/onlineLogo.svg';
import { ReactComponent as InPerson } from '../../assets/inPerson.svg';
import { BiVolumeMute } from 'react-icons/bi';
import { GoUnmute } from 'react-icons/go';
import { LuClock2 } from 'react-icons/lu';
import Slider from 'react-slick';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  parse,
  stringify,
} from '../../util/urlHelpers';
import { convertMoneyToNumber, formatMoney } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import {
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  AvatarLarge,
  AvatarSmall,
  OnlineWorkout,
  OfflineWorkout,
  Modal,
  Form,
  PrimaryButton,
  FieldRadioButton,
  FieldDateInput,
  FieldCustomSelect,
  SelectOption,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
  findMinMaxPriceRange,
  formatPriceRange,
  formatPriceRangeSingle,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';
import css from './ListingPage.module.css';
import { displayPrice } from '../../util/configHelpers.js';
import MotivationalImg from '../../assets/Rectangle 13.jpg';
import CustomTopbar from '../TopbarContainer/CustomTopbar.js';
import CustomFooter from '../../components/CustomFooter/CustomFooter.js';
import fav1Img from '../../assets/fav1.jpg';
import fav2Img from '../../assets/fav2.jpg';
import fav3Img from '../../assets/fav3.jpg';
import fav4Img from '../../assets/fav4.jpg';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { RWebShare } from 'react-web-share';
import SliderFaqComponent from '../../components/SliderFaqComponent/SliderFaqComponent.js';
import { FaRegEdit } from 'react-icons/fa';
import { ReactComponent as CustomNextArrow } from '../../assets/rightArrow.svg';
import { ReactComponent as CustomPrevArrow } from '../../assets/leftArrow.svg';
import { ReactComponent as Calender } from '../../assets/calender.svg';
import { listingServiceType } from '../../custom-config';
import { ReactComponent as Clock } from '../../assets/clockNew.svg';
import moment from 'moment';
import {
  FacebookShareButton,
  WhatsappShareButton,
  RedditShareButton,
  TwitterShareButton,
  TelegramShareButton,
  EmailShareButton,
  LinkedinShareButton,
  LineShareButton,
  PinterestShareButton,
  InstapaperShareButton,
  FacebookMessengerShareButton,
  // WhatsappIcon,
  RedditIcon,
  TelegramIcon,
  EmailIcon,
  LineIcon,
  PinterestIcon,
  // FacebookIcon,
  // TwitterIcon,
  LinkedinIcon,
  InstapaperIcon,
  // FacebookMessengerIcon,
} from 'react-share';
import { GrFormSubtract, GrFormAdd } from 'react-icons/gr';
import { TbClockHour9 } from 'react-icons/tb';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { ReactComponent as WhatsappIcon } from '../../assets/whatsappIcon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebookIcon.svg';
import { ReactComponent as TwitterIcon } from '../../assets/twitterIcon.svg';
import { ReactComponent as FacebookMessengerIcon } from '../../assets/messenger.svg';
import { ReactComponent as CopyLinkIcon } from '../../assets/copyLinkIcon.svg';
import { PriceMaybe } from '../../components/OrderPanel/OrderPanel.js';
import ModalImageCarousal from '../../components/ModalImageCarousal/ModalImageCarousal.js';
const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const MAX_GROUP_SIZE = 1;
const MODAL_BREAKPOINT = 1023;
export const tagRegex = /<\s*(p|ul|ol|li|div|a|span)\b[^>]*>/i;

const { Money } = sdkTypes;
const { UUID } = sdkTypes;
const arr = [...new Array(MAX_GROUP_SIZE)];
const groupArr = arr.map((_, indx) => ({
  value: indx + 1,
  label: `${indx + 1} Guest${indx > 0 ? 's' : ''}`,
}));
export const SeatBox = props => {
  const {
    maxSeats,
    onSeatsUpdate = () => {},
    rootClassName,
    showMaxSeats,
    maxSeatsInfoClassname,
    seatValue,
  } = props;
  const [seats, setSeats] = useState(seatValue || 1);
  useEffect(() => {
    onSeatsUpdate(seats);
  }, [seats]);
  const seatBoxRootClassName = classNames(css.seatBoxRoot, rootClassName);
  const maxSeatsClasses = classNames(css.maxSeatsSpan, maxSeatsInfoClassname);
  return (
    <div className={seatBoxRootClassName}>
      <button type="button" onClick={e => setSeats(Math.max(seats - 1, 1))}>
        <GrFormSubtract />
      </button>
      <input
        value={seats}
        onChange={e => {
          const inputValue = e.target.value;
          if (inputValue === '' || /^[0-9\b]+$/.test(inputValue)) {
            setSeats(Math.min(maxSeats, inputValue));
          }
        }}
      />
      <button type="button" onClick={e => setSeats(Math.min(seats + 1, maxSeats))}>
        <GrFormAdd />
      </button>
      {showMaxSeats && <span className={maxSeatsClasses}>Available seats: {maxSeats}</span>}
    </div>
  );
};
const getSessionType = (selectedEvent, intl) => {
  const {
    eventOfflineCheckBox,
    eventLocation,
    eventOnlineCheckBox,
    customEventLocation,
    locationPrivateCheckBox,
  } = selectedEvent;
  const sessionTypeArr = [];
  const location =
    (eventLocation && eventLocation.selectedPlace && eventLocation.selectedPlace.address) ||
    customEventLocation;
  const locationText =
    locationPrivateCheckBox &&
    Array.isArray(locationPrivateCheckBox) &&
    locationPrivateCheckBox.length > 0 &&
    locationPrivateCheckBox?.[0] === 'hideLocation'
      ? 'Location to be shared to ticket holders after purchase'
      : location;
  if (
    eventOfflineCheckBox &&
    Array.isArray(eventOfflineCheckBox) &&
    eventOfflineCheckBox.length > 0 &&
    location
  ) {
    sessionTypeArr.push({
      value: listingServiceType.offline,
      label: (
        <div className={css.labelWrapper}>
          <OfflineWorkout className={css.labelIcon} />
          <span className={css.labelText}>{locationText}</span>
        </div>
      ),
    });
  }
  if (eventOnlineCheckBox && Array.isArray(eventOnlineCheckBox) && eventOnlineCheckBox.length > 0) {
    sessionTypeArr.push({
      value: listingServiceType.online,
      label: (
        <div className={css.labelWrapper}>
          <OnlineWorkout className={css.labelIcon} />
          <span className={css.labelText}>
            {intl.formatMessage({ id: 'BookingTimeForm.OnlineBooking' })}
          </span>
        </div>
      ),
    });
  }
  if (sessionTypeArr.length > 1) {
    sessionTypeArr.unshift({
      value: undefined,
      label: (
        <div>
          <FormattedMessage id="BookingTimeForm.bookingType" />
        </div>
      ),
    });
  }
  return sessionTypeArr;
};
export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const trainersCard = new Array(
    fav1Img,
    fav2Img,
    fav3Img,
    fav4Img,
    fav1Img,
    fav2Img,
    fav3Img,
    fav4Img
    // fav3Img,
    // fav4Img
  );

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    eventSlots,
  } = props;
  const [averageReview, setAverageReview] = useState(0);
  const [bookEventModal, setBookEventModal] = useState(false);
  const [eventBookingDetailsState, setEventBookingDetailsState] = useState({});
  const [imageLength, setImageLength] = useState(0);
  const average = useMemo(() => {
    if (!reviews || reviews.length === 0) return 0;

    const total = reviews.reduce((sum, review) => sum + (review?.attributes?.rating || 0), 0);
    return total / reviews.length;
  }, [reviews]);

  useEffect(() => {
    setAverageReview(average);
  }, [average]);
  // const urlShr = typeof window !== undefined && window.location ? window.location.href : null;
  const urlShr = config?.marketplaceRootURL + location?.pathname;
  const [openBookingModal, setOpenBookingModal] = useState(false);
  const [ismuted, setIsMuted] = useState(true);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isShareHoverOpen, setIsShareHoverOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [imageLinks, setImageLinks] = useState([]);
  const [initialSlide, setInitialSlide] = useState(0);
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );
  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const videoRef = useRef(null);
  const handleHover = () => {
    videoRef?.current?.play();
  };

  const toggleMute = e => {
    e.preventDefault();
    // e.stopPropagation();
    if (videoRef.current.muted) {
      videoRef.current.muted = false;
      setIsMuted(false);
    } else {
      videoRef.current.muted = true;
      setIsMuted(true);
    }
  };

  const handleMouseLeave = () => {
    videoRef?.current?.pause();
    if (videoRef && videoRef.current && videoRef.current.currentTime) {
      videoRef.current.currentTime = 0;
    }
  };
  const listingConfig = config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;
  const {
    personal,
    servicesList,
    eventsList,
    quote,
    philosophy,
    workout,
    fitnessTip,
    fitness,
    additionalImageOrVedios,
  } = currentListing?.attributes?.publicData || '';
  const sortedEventList = eventsList?.sort(
    (a, b) => new Date(a?.eventStartDate) - new Date(b?.eventStartDate)
  );
  const [servicesReadMore, setServicesReadMore] = useState([]);
  const [eventsReadMore, setEventsReadMore] = useState([]);
  useEffect(() => {
    setServicesReadMore(new Array(servicesList?.length).fill(true));
  }, [servicesList]);
  useEffect(() => {
    setEventsReadMore(new Array(eventsList?.length).fill(true));
  }, [eventsList]);

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = (
    <>
      {windowWidth > 767 ? (
        <div className={css.desktopTopbar}>
          <TopbarContainer />
        </div>
      ) : (
        <div className={css.mobileTopbar}>
          <TopbarContainer customAnimation={true} />
        </div>
      )}

      {/* <CustomTopbar /> */}
    </>
  );
  // const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    // title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;
  const { firstName = '', lastName = '' } = currentListing.attributes.publicData;
  const title = `${firstName}` + ' ' + `${lastName}`;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const transactionProcessAlias = publicData?.transactionProcessAlias;
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const isBooking = isBookingProcess(processName);
  const isPurchase = isPurchaseProcess(processName);
  const processType = isBooking ? ('booking' ? isPurchase : 'purchase') : 'inquiry';

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const noPayoutDetailsSetWithOwnListing =
    isOwnListing && processType !== 'inquiry' && !currentUser?.attributes?.stripeConnected;
  const payoutDetailsWarning = noPayoutDetailsSetWithOwnListing ? (
    <span className={css.payoutDetailsWarning}>
      <FormattedMessage id="ListingPage.payoutDetailsWarning" values={{ processType }} />
      <NamedLink name="StripePayoutPage">
        <FormattedMessage id="ListingPage.payoutDetailsWarningLink" />
      </NamedLink>
    </span>
  ) : null;

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = {
    params,
    history,
    routes: routeConfiguration,
  };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else if (values.serviceName === 'event') {
      onSubmit(values);
    } else {
      const serviceName = values.serviceName;
      const service = servicesList?.find(s => s.servicesName === serviceName);
      const servicePrice = JSON.parse(service.servicePrice);
      onSubmit({
        ...values,
        isFree: servicePrice.amount === 0,
      });
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options =>
    options.map(o => ({
      key: `${o.option}`,
      label: o.label,
    }));
  const listingTitle = `${currentListing?.attributes?.publicData?.firstName} ${currentListing?.attributes?.publicData?.lastName}`;

  const priceArray =
    servicesList?.length > 1
      ? servicesList?.map(m => {
          return JSON.parse(m?.servicePrice);
        })
      : price?.amount;
  const { min, max } = priceArray.length > 1 && findMinMaxPriceRange(priceArray);
  const priceRange =
    min !== undefined && max !== undefined
      ? formatPriceRange(min, max)
      : formatPriceRangeSingle(priceArray);
  const isVideo =
    currentListing?.attributes?.publicData?.uploadVedio?.[0]?.key?.split('/')?.[0] === 'videos'
      ? true
      : false;

  // const handleAirDrop = url => {
  //   if (typeof window !== undefined && window.navigator.userAgent.indexOf('AppleWebKit')) {
  //     const file = new File([url], 'listing.txt', { type: 'text/plain' });
  //     const data = {
  //       files: [file],
  //       title: title,
  //       text: url,
  //     };

  //     if (typeof window !== undefined && window.navigator.canShare(data)) {
  //       window.navigator
  //         .share(data)
  //         .then(() => {})
  //         .catch(console.error);
  //     } else {
  //     }
  //   } else {
  //   }
  // };

  const settings = {
    dots: false,
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    arrows: true,
    initialSlide: initialSlide,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<CustomFooter />}>
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>
            {/* {currentListing.id && noPayoutDetailsSetWithOwnListing ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                showNoPayoutDetailsSet={noPayoutDetailsSetWithOwnListing}
              />
            ) : null}
            {currentListing.id ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
              />
            ) : null}
            <SectionGallery
              listing={currentListing}
              variantPrefix={config.layout.listingImage.variantPrefix}
            />
            <div className={css.mobileHeading}>
              <H4 as="h1" className={css.orderPanelTitle}>
                <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
              </H4>
            </div>
            <SectionTextMaybe text={description} showAsIngress />
            <SectionDetailsMaybe
              publicData={publicData}
              metadata={metadata}
              listingConfig={listingConfig}
              intl={intl}
            />
            {listingConfig.listingFields.reduce((pickedElements, config) => {
              const { key, enumOptions, includeForListingTypes, scope = 'public' } = config;
              const listingType = publicData?.listingType;
              const isTargetListingType =
                includeForListingTypes == null || includeForListingTypes.includes(listingType);

              const value =
                scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
              const hasValue = value != null;
              return isTargetListingType && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                ? [
                    ...pickedElements,
                    <SectionMultiEnumMaybe
                      key={key}
                      heading={config?.showConfig?.label}
                      options={createFilterOptions(enumOptions)}
                      selectedOptions={value || []}
                    />,
                  ]
                : isTargetListingType && hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                ? [
                    ...pickedElements,
                    <SectionTextMaybe key={key} heading={config?.showConfig?.label} text={value} />,
                  ]
                : pickedElements;
            }, [])}

            <SectionMapMaybe
              geolocation={geolocation}
              publicData={publicData}
              listingId={currentListing.id}
              mapsConfig={config.maps}
            />
            <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
            <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            /> */}
            {/* {currentListing.id ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
              />
            ) : null} */}
            <div>
              <div
                className={css.editButton}
                onMouseOver={() => {
                  setIsEditModalOpen(true);
                }}
                onMouseLeave={() => {
                  setIsEditModalOpen(false);
                }}
              >
                {currentUser &&
                  currentUser?.attributes?.profile?.publicData?.isTrainer &&
                  isOwnListing && (
                    <NamedLink
                      name="NewListingPage"
                      // className={classNames(css.listingLinkText, css.highlight)}
                    >
                      <FaRegEdit />
                    </NamedLink>
                  )}
              </div>
              {isEditModalOpen && <div className={css.hoverDiv}>Edit</div>}
              <div className={css.infoDiv}>
                <div>{listingTitle}</div>
                <div>{personal}</div>
                <div>{description}</div>
                <div>{priceRange}</div>
                <div>
                  {reviews?.length > 0
                    ? `${averageReview.toFixed(1)}* : ${reviews.length} Reviews`
                    : 'No Reviews'}
                </div>
                <div
                  className={css.shareContainer}
                  tabIndex={0}
                  onBlur={e => {
                    if (!e.currentTarget.contains(e.relatedTarget)) {
                      setIsShareModalOpen(false);
                    }
                  }}
                >
                  <span
                    onClick={() => {
                      setIsShareModalOpen(true);
                    }}
                    onMouseOver={() => setIsShareHoverOpen(true)}
                    onMouseLeave={() => {
                      setIsShareHoverOpen(false);
                    }}
                  >
                    <Share />
                  </span>
                  {/* {typeof window !== undefined && isMounted && (
                    <RWebShare
                      data={{
                        text: '',
                        url: urlShr,
                        // url: 'https://fleggs-production.up.railway.app/l/kuljitsingh/6659632a-f5d4-4bce-9825-b50de673588c',

                        title: '',
                      }}
                      onClick={() => console.log('shared successfully!')}
                    >
                      <span
                        onMouseOver={() => setIsShareHoverOpen(true)}
                        onMouseLeave={() => {
                          setIsShareHoverOpen(false);
                        }}
                      >
                        <Share />
                      </span>
                    </RWebShare>
                  )} */}
                  {isShareHoverOpen && <div className={css.hoverDiv}>Share</div>}
                  {isShareModalOpen && (
                    <div className={css.shareDiv}>
                      {/* <div className={css.shareButton}>share</div> */}
                      <div className={css.shareIconDiv}>
                        <div className={css.buttonWrapper}>
                          <WhatsappShareButton url={urlShr}>
                            <WhatsappIcon />
                          </WhatsappShareButton>
                          <div className={css.iconName}>Whatsapp</div>
                        </div>
                        <div className={css.buttonWrapper}>
                          <FacebookMessengerShareButton url={urlShr}>
                            <FacebookMessengerIcon />
                          </FacebookMessengerShareButton>
                          {/* </div> */}
                          <div className={css.iconName}>Messenger</div>
                        </div>
                        <div className={css.buttonWrapper}>
                          <FacebookShareButton url={urlShr}>
                            <FacebookIcon />
                          </FacebookShareButton>
                          <div className={css.iconName}>Facebook</div>
                        </div>
                        <div className={css.buttonWrapper}>
                          <TwitterShareButton url={urlShr}>
                            <TwitterIcon />
                          </TwitterShareButton>
                          <div className={css.iconName}>X</div>
                        </div>
                        <div className={css.buttonWrapper}>
                          <div className={css.linkCopyShare}>
                            <input
                              type="text"
                              ref={inputRef}
                              value={urlShr}
                              readOnly
                              className={css.linkCopyBox}
                            />
                            <button
                              className={css.cpybtn}
                              onClick={e => {
                                inputRef.current.select();
                                document.execCommand('copy');
                                setIsCopySuccess(true);
                                const closeDiv = setTimeout(() => {
                                  // setIsShareModalOpen(false);
                                  setIsCopySuccess(false);
                                }, 2000);
                                return () => clearTimeout(closeDiv);
                              }}
                            >
                              <CopyLinkIcon />
                            </button>
                          </div>
                          <div className={css.iconName}>
                            {isCopySuccess ? 'Link Copied!' : 'Copy Link'}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className={css.orderColumnForProductLayout}> */}
          {/* <SectionGallery
              listing={currentListing}
              variantPrefix={config.layout.listingImage.variantPrefix}
            /> */}
          <div
            className={css.ListingImage}
            onMouseEnter={handleHover}
            onMouseLeave={handleMouseLeave}
          >
            {!isVideo ? (
              <>
                <img
                  src={currentListing?.attributes?.publicData?.uploadVedio?.[0]?.location}
                  className={css.blurredMedia}
                />
                <img src={currentListing?.attributes?.publicData?.uploadVedio?.[0]?.location} />
              </>
            ) : (
              <>
                <video
                  ref={videoRef}
                  className={classNames(css.previewVideo, css.blurredMedia)}
                  muted="muted"
                >
                  {/* Source of the video */}
                  <source
                    src={currentListing?.attributes?.publicData?.uploadVedio?.[0]?.location}
                    // type="video/mp4"
                  />
                  {/* Text to be displayed if the browser does not support the video */}
                  Your browser does not support the video tag.
                </video>
                <video ref={videoRef} className={css.previewVideo} muted="muted">
                  {/* Source of the video */}
                  <source
                    src={currentListing?.attributes?.publicData?.uploadVedio?.[0]?.location}
                    // type="video/mp4"
                  />
                  {/* Text to be displayed if the browser does not support the video */}
                  Your browser does not support the video tag.
                </video>
                <div
                  className={css.muteButton}
                  onClick={e => toggleMute(e)}
                  onMouseEnter={handleHover}
                  onMouseLeave={handleMouseLeave}
                >
                  {ismuted ? <BiVolumeMute /> : <GoUnmute />}
                </div>
              </>
            )}
            <div className={css.listingMobileTitle}>
              <div className={css.mobileTitle}>{listingTitle}</div>
              <div className={css.mobilePersonal}>{personal}</div>
            </div>
            <div
              className={css.mobileShare}
              tabIndex={0}
              onBlur={e => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                  setIsShareModalOpen(false);
                }
              }}
            >
              <span
                onClick={() => {
                  setIsShareModalOpen(true);
                }}
                onMouseOver={() => setIsShareHoverOpen(true)}
                onMouseLeave={() => {
                  setIsShareHoverOpen(false);
                }}
              >
                <Share />
              </span>
              {/* {typeof window !== undefined && isMounted && (
                <RWebShare
                  data={{
                    text: '',
                    url: urlShr,
                    // url: 'https://fleggs-production.up.railway.app/l/kuljitsingh/6659632a-f5d4-4bce-9825-b50de673588c',

                    title: '',
                  }}
                  onClick={() => console.log('shared successfully!')}
                >
                  <span
                    onMouseOver={() => setIsShareHoverOpen(true)}
                    onMouseLeave={() => {
                      setIsShareHoverOpen(false);
                    }}
                  >
                    <Share />
                  </span>
                </RWebShare>
              )} */}
              {/* {isShareHoverOpen && <div className={css.shareDiv}>Share</div>} */}
              {isShareModalOpen && (
                <div className={css.shareDiv}>
                  {/* <div className={css.shareButton}>share</div> */}
                  <div className={css.shareIconDiv}>
                    <div className={css.buttonWrapper}>
                      <WhatsappShareButton url={urlShr}>
                        <WhatsappIcon />
                      </WhatsappShareButton>
                      <div className={css.iconName}>Whatsapp</div>
                    </div>
                    <div className={css.buttonWrapper}>
                      <FacebookMessengerShareButton url={urlShr}>
                        <FacebookMessengerIcon />
                      </FacebookMessengerShareButton>
                      {/* </div> */}
                      <div className={css.iconName}>Messenger</div>
                    </div>
                    <div className={css.buttonWrapper}>
                      <FacebookShareButton url={urlShr}>
                        <FacebookIcon />
                      </FacebookShareButton>
                      <div className={css.iconName}>Facebook</div>
                    </div>
                    <div className={css.buttonWrapper}>
                      <TwitterShareButton url={urlShr}>
                        <TwitterIcon />
                      </TwitterShareButton>
                      <div className={css.iconName}>X</div>
                    </div>
                    <div className={css.buttonWrapper}>
                      <div className={css.linkCopyShare}>
                        <input
                          type="text"
                          ref={inputRef}
                          value={urlShr}
                          readOnly
                          className={css.linkCopyBox}
                        />
                        <button
                          className={css.cpybtn}
                          onClick={e => {
                            inputRef.current.select();
                            document.execCommand('copy');
                            setIsCopySuccess(true);
                            const closeDiv = setTimeout(() => {
                              // setIsShareModalOpen(false);
                              setIsCopySuccess(false);
                            }, 2000);
                            return () => clearTimeout(closeDiv);
                          }}
                        >
                          <CopyLinkIcon />
                        </button>
                      </div>
                      <div className={css.iconName}>
                        {isCopySuccess ? 'Link Copied!' : 'Copy Link'}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <OrderPanel
            className={css.productOrderPanel}
            listing={currentListing}
            isOwnListing={isOwnListing}
            onSubmit={handleOrderSubmit}
            authorLink={
              <NamedLink
                className={css.authorNameLink}
                name="ListingPage"
                params={params}
                to={{ hash: '#author' }}
              >
                {authorDisplayName}
              </NamedLink>
            }
            title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
            titleDesktop={
              <H4 as="h1" className={css.orderPanelTitle}>
                <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
              </H4>
            }
            payoutDetailsWarning={payoutDetailsWarning}
            author={ensuredAuthor}
            onManageDisableScrolling={onManageDisableScrolling}
            onContactUser={onContactUser}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            validListingTypes={config.listing.listingTypes}
            marketplaceCurrency={config.currency}
            dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
            marketplaceName={config.marketplaceName}
            openBookingModal={openBookingModal}
            fromListingDetialsPage={true}
          />
          {/* </div> */}
        </div>

        <div className={css.mobileInformation}>
          <div className={css.mobileFirstDiv}>
            <div className={css.priceRangeMobile}>{priceRange}</div>
            <div
              className={css.editButton}
              onMouseOver={() => {
                setIsEditModalOpen(true);
              }}
              onMouseLeave={() => {
                setIsEditModalOpen(false);
              }}
            >
              {currentUser &&
                currentUser?.attributes?.profile?.publicData?.isTrainer &&
                isOwnListing && (
                  <NamedLink
                    name="NewListingPage"
                    // className={classNames(css.listingLinkText, css.highlight)}
                  >
                    <FaRegEdit />
                  </NamedLink>
                )}
            </div>
            {isEditModalOpen && <div className={css.hoverDiv}>Edit</div>}
          </div>
          {/* <div>4.9*: 925 Reviews</div> */}
          <div>
            {reviews?.length > 0
              ? `${averageReview.toFixed(1)}* : ${reviews.length} Reviews`
              : 'No Reviews'}
          </div>
          <div>{description}</div>
        </div>
        <div className={css.bookingDiv}>
          {servicesList?.length > 0 && (
            <>
              <div className={css.service}>Select Service:</div>
              <div className={css.Line}></div>
            </>
          )}

          <div className={css.serviceWrapper}>
            {servicesList?.map((m, indx) => {
              const groupEmelent = m?.groupEquipment?.split(',');
              const oneToOneGroupElement = m?.oneToOneEquipment?.split(',');
              const parsedPrice = JSON.parse(m.servicePrice);
              const moneyTypePrice = new Money(parsedPrice.amount, config.currency);
              const { formattedPrice } = priceData(moneyTypePrice, config.currency, intl);
              // const [isReadMore, setIsReadMore] = useState(true);
              const isTruncated = m.tellUsMore.split(' ').length > 20;
              const description =
                isTruncated && servicesReadMore[indx]
                  ? m?.tellUsMore?.slice(0, 150) + '...'
                  : m?.tellUsMore;
              const duration = m?.duration;
              return (
                <div className={css.serviceCard} key={indx}>
                  {m?.servicesName === 'other' && m?.other ? (
                    <div className={css.serviceName}>{`${m?.other}`}</div>
                  ) : (
                    <div className={css.serviceName}>{m?.servicesName}</div>
                  )}

                  <div className={css.desc}>
                    {/* Mind and body practice that can build strength and flexibility. */}
                    {description}
                  </div>
                  {isTruncated &&
                    (servicesReadMore[indx] ? (
                      <div
                        className={css.readButton}
                        onClick={() => {
                          setServicesReadMore(prevState => {
                            const newState = [...prevState];
                            newState[indx] = !newState[indx];
                            return newState;
                          });
                        }}
                      >
                        Read More
                      </div>
                    ) : (
                      <div
                        className={css.readButton}
                        onClick={() => {
                          setServicesReadMore(prevState => {
                            const newState = [...prevState];
                            newState[indx] = !newState[indx];
                            return newState;
                          });
                        }}
                      >
                        Read Less
                      </div>
                    ))}
                  <div className={css.propertyDIv}>
                    {formattedPrice && (
                      <div className={css.property}>
                        <span>{`${formattedPrice} ph`}</span>
                      </div>
                    )}
                    {m?.oneToOneCheckBox && (
                      <div className={css.property}>
                        <span>{m.oneToOneCheckBox?.length > 0 && '1:1'}</span>
                      </div>
                    )}
                    {oneToOneGroupElement?.length === 1 ? (
                      <div className={css.property}>
                        <span>{m?.oneToOneEquipment}</span>
                      </div>
                    ) : (
                      oneToOneGroupElement?.map(m => (
                        <div className={css.property}>
                          <span>{m}</span>
                        </div>
                      ))
                    )}
                    {m?.groupCheckbox && (
                      <div className={css.property}>
                        <span>{m?.groupCheckbox?.[0]}</span>
                      </div>
                    )}
                    {groupEmelent?.length === 1 ? (
                      <div className={css.property}>
                        <span>{m?.groupEquipment}</span>
                      </div>
                    ) : (
                      groupEmelent?.map(m => (
                        <div className={css.property}>
                          <span>{m}</span>
                        </div>
                      ))
                    )}
                    {m?.onlineCheckBox?.length > 0 && <OnlineWorkout className={css.workout} />}
                    {m?.offlineCheckBox?.length > 0 && <OfflineWorkout className={css.workout} />}
                    {duration && (
                      <div className={css.durationProperty}>
                        <LuClock2 />
                        <span>{duration}</span>
                      </div>
                    )}
                  </div>
                  <div className={css.btnDiv}>
                    <button
                      type="button"
                      className={css.bookBtn}
                      disabled={!!isOwnListing}
                      onClick={() => {
                        const { pathname, search, state } = location;
                        const searchString = `?${stringify({
                          ...parse(search),
                          orderOpen: true,
                          indx,
                        })}`;
                        history.push(`${pathname}${searchString}`, state);
                      }}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          {eventsList?.length > 0 &&
            eventsList?.filter(e => {
              const eventStartDate = moment(e.eventStartDate).format('YYYY/MM/DD');
              const eventStartTime = e.eventStartTime.key;
              const startDate = moment(`${eventStartDate} ${eventStartTime}`, 'YYYY-MM-DD hh:mm A');
              const isPast = startDate.isSameOrAfter(moment());
              return isPast;
            }).length > 0 && (
              <>
                <div className={css.events}>Events:</div>
                <div className={css.Line}></div>
                <div className={css.eventWrapper}>
                  <Slider {...settings}>
                    {/* {eventsList */}
                    {sortedEventList
                      ?.filter(e => {
                        const eventStartDate = moment(e.eventStartDate).format('YYYY/MM/DD');
                        const eventStartTime = e.eventStartTime.key;
                        const startDate = moment(
                          `${eventStartDate} ${eventStartTime}`,
                          'YYYY-MM-DD hh:mm A'
                        );
                        const isPast = startDate.isSameOrAfter(moment());
                        return isPast;
                      })
                      ?.map((m, i) => {
                        const sliderSettings = {
                          dots: false,
                          infinite: false,
                          speed: 500,
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          // nextArrow: <CustomNextArrow/>,
                          // prevArrow: <CustomPrevArrow/>,
                          arrows: m?.eventS3Media?.length > 1 ? true : false,
                          initialSlide: 0, // or any appropriate initial slide index
                          responsive: [
                            {
                              breakpoint: 767,
                              settings: {
                                arrows: true,
                              },
                            },
                          ],
                        };
                        // setImageLength(m?.eventS3Media?.length)
                        const formattedDate = moment(m.eventStartDate).format('dddd MMMM DD, YYYY');

                        const eventStartTime = m?.eventStartTime?.label;
                        const eventEndTime = m?.eventEndTime?.label;
                        const startTime = moment(eventStartTime, 'hh:mm A');
                        const endTime = moment(eventEndTime, 'hh:mm A');
                        const moneyTypePrice = new Money(
                          m.eventPrice.amount,
                          m.eventPrice.currency
                        );
                        const { formattedPrice } = priceData(moneyTypePrice, config.currency, intl);
                        const modifiedAmount = formattedPrice.endsWith('.00')
                          ? formattedPrice.slice(0, -3)
                          : formattedPrice;

                        // Calculate the difference in minutes
                        const duration = moment.duration(endTime.diff(startTime));
                        const hours = Math.floor(duration.asHours());
                        const minutes = duration.minutes();
                        const hideLocation = m?.locationPrivateCheckBox?.[0] === 'hideLocation';
                        const applicableslot = eventSlots.find(es => es.eventId === m.eventId);
                        const remainingSeats = applicableslot?.attributes?.seats || 0;
                        const eventDetails = m?.eventDetails;
                        const EventLocation = !!m?.eventLocation?.selectedPlace?.address
                          ? m?.eventLocation?.selectedPlace?.address
                          : m?.customEventLocation;
                        const onReadMoreClick = () => {
                          const newState = eventsReadMore?.includes(m.eventId)
                            ? eventsReadMore?.filter(eb => eb !== m.eventId)
                            : [...eventsReadMore, m.eventId];
                          setEventsReadMore(newState);
                        };
                        const showReadmoreBtn = eventDetails?.length > 150;
                        const isFullDetails = eventsReadMore?.includes(m.eventId);
                        const readmoreBtnTxt = isFullDetails ? 'Read less' : 'Read more';
                        return (
                          <div key={m.eventId} className={css.eachWrapper}>
                            {/* <div
                              className={classNames(css.eventImage, css.noneOnMobile)}
                              onClick={() => {
                                setImageLinks(m?.eventS3Media), setIsOpen(true);
                              }}
                            >
                              {m?.eventS3Media?.[0]?.location?.includes('/images/') ? (
                                <img
                                  src={m?.eventS3Media?.[0]?.location}
                                  className={css.imagePreview}
                                  alt="Preview"
                                />
                              ) : m?.eventS3Media?.[0]?.location?.includes('/videos/') ? (
                                <video
                                  ref={videoRef}
                                  onMouseEnter={handleHover}
                                  onMouseLeave={handleMouseLeave}
                                  className={css.imagePreview}
                                  muted="muted"
                                >
                                  <source src={m?.eventS3Media?.[0]?.location} />
                                </video>
                              ) : null}
                            </div> */}
                            <div className={classNames(css.eventImageForInner, css.noneOnMobile)}>
                              <Slider {...sliderSettings}>
                                {m?.eventS3Media?.map((media, index) => (
                                  <div key={index} className={css.sliderItem}>
                                    {media?.location?.includes('/images/') ? (
                                      <div
                                        className={css.sliderImgContainer}
                                        onClick={() => {
                                          setImageLinks(m?.eventS3Media), setIsOpen(true);
                                        }}
                                      >
                                        <img
                                          src={media.location}
                                          className={css.imagePreview}
                                          alt={`Preview ${index}`}
                                        />
                                      </div>
                                    ) : media?.location?.includes('/videos/') ? (
                                      <div
                                        className={css.sliderImgContainer}
                                        onClick={() => {
                                          setImageLinks(m?.eventS3Media), setIsOpen(true);
                                        }}
                                      >
                                        <video className={css.imagePreview} muted controls>
                                          <source src={media.location} />
                                        </video>
                                      </div>
                                    ) : null}
                                  </div>
                                ))}
                              </Slider>
                            </div>

                            <div className={classNames(css.eventDetails, css.noneOnMobile)}>
                              <div className={css.eventDetailsFirstChild}>
                                <div className={css.event}>Event</div>
                                <div className={css.eventTitle}>{m?.eventTitle}</div>
                                {tagRegex.test(eventDetails) ? (
                                  <div
                                    className={classNames(css.eventDescription, {
                                      [css.fullDet]: isFullDetails,
                                    })}
                                    dangerouslySetInnerHTML={{
                                      __html: eventDetails,
                                    }}
                                  ></div>
                                ) : (
                                  <div
                                    className={classNames(css.eventDescription, {
                                      [css.fullDet]: isFullDetails,
                                    })}
                                  >
                                    {eventDetails}
                                  </div>
                                )}
                                {showReadmoreBtn ? (
                                  <div className={css.eventReadButton} onClick={onReadMoreClick}>
                                    {readmoreBtnTxt}
                                  </div>
                                ) : null}
                                <div className={css.ownerTitle}>
                                  <AvatarSmall
                                    className={css.eventAvatar}
                                    user={ensuredAuthor}
                                    disableProfileLink
                                  />
                                  <div>{listingTitle}</div>
                                </div>
                                <div className={css.locationContainer}>
                                  <div className={css.locationInidividual}>
                                    <div>
                                      {m?.eventOnlineCheckBox?.length > 0 && (
                                        <OnlineWorkout className={css.workout} />
                                      )}
                                    </div>
                                    <div>
                                      {m?.eventOfflineCheckBox?.length > 0 && (
                                        <OfflineWorkout className={css.workout} />
                                      )}
                                    </div>
                                  </div>
                                  {EventLocation && (
                                    <div className={css.locationAddress}>
                                      {hideLocation
                                        ? 'Location to be shared to ticket holders after purchase'
                                        : EventLocation}
                                    </div>
                                  )}
                                </div>
                                <div className={css.dateAndTimeContainer}>
                                  <div className={css.eventDate}>
                                    <Calender />
                                    {formattedDate}
                                  </div>
                                  <div className={css.eventDate}>
                                    <Clock />
                                    {m?.eventStartTime?.label.toLowerCase()}-
                                    {m?.eventEndTime?.label.toLowerCase()} : {hours > 0 && hours}{' '}
                                    {hours > 0 && (hours > 1 ? 'hours' : 'hour')}{' '}
                                    {minutes > 0 && minutes}{' '}
                                    {minutes > 0 && (minutes > 1 ? 'mins' : 'min')}
                                  </div>
                                </div>
                              </div>
                              <div className={css.eventDetailsSecondChild}>
                                <div className={css.priceFormat}>
                                  {modifiedAmount === '£0' ? 'Free' : modifiedAmount}
                                </div>
                                <div className={css.btnWrapper}>
                                  <button
                                    type="button"
                                    className={classNames(css.bookBtn, css.eventBookBtn)}
                                    disabled={!!isOwnListing || !remainingSeats}
                                    onClick={() => {
                                      if (!remainingSeats) return;
                                      const bookingType =
                                        m.eventOfflineCheckBox?.[0] && m.eventOnlineCheckBox?.[0]
                                          ? null
                                          : m.eventOfflineCheckBox?.[0] ||
                                            m.eventOnlineCheckBox?.[0];
                                      setEventBookingDetailsState({
                                        ...m,
                                        remainingSeats,
                                        guests: 1,
                                        bookingType,
                                      });
                                      setBookEventModal(true);
                                    }}
                                  >
                                    Buy Ticket
                                  </button>
                                  <div className={css.availableTickets}>
                                    {remainingSeats} tickets available
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={classNames(css.mobileEventHeader, css.noneOnDeskTop)}>
                              {/* <div
                                className={css.mobileImageEventDiv}
                                // onClick={() => {
                                //   setImageLinks(m?.eventS3Media), setIsOpen(true);
                                // }}
                              > */}
                              {/* {m?.eventS3Media?.[0]?.location?.includes('/images/') ? (
                                  <img
                                    src={m?.eventS3Media?.[0]?.location}
                                    className={css.imagePreview}
                                    alt="Preview"
                                  />
                                ) : m?.eventS3Media?.[0]?.location?.includes('/videos/') ? (
                                  <video
                                    ref={videoRef}
                                    onMouseEnter={handleHover}
                                    onMouseLeave={handleMouseLeave}
                                    className={css.imagePreview}
                                    muted="muted"
                                  >
                                    <source src={m?.eventS3Media?.[0]?.location} />
                                  </video>
                                ) : null} */}
                              {/* </div> */}

                              <Slider {...sliderSettings}>
                                {m?.eventS3Media?.map((media, index) => (
                                  <div key={index} className={css.sliderItem}>
                                    {media?.location?.includes('/images/') ? (
                                      <div
                                        className={css.sliderImgContainer}
                                        onClick={() => {
                                          setImageLinks(m?.eventS3Media), setIsOpen(true);
                                        }}
                                      >
                                        <img
                                          src={media.location}
                                          className={css.imagePreview}
                                          alt={`Preview ${index}`}
                                        />
                                      </div>
                                    ) : media?.location?.includes('/videos/') ? (
                                      <div
                                        className={css.sliderImgContainer}
                                        onClick={() => {
                                          setImageLinks(m?.eventS3Media), setIsOpen(true);
                                        }}
                                      >
                                        <video className={css.imagePreview} muted controls>
                                          <source src={media.location} />
                                        </video>
                                      </div>
                                    ) : null}
                                  </div>
                                ))}
                              </Slider>
                              <div className={css.mobileEventNames}>
                                <div className={css.event}>Event</div>
                                <div className={css.eventTitle}>{m?.eventTitle}</div>
                              </div>
                            </div>
                            <div className={classNames(css.mobileEventDetails, css.noneOnDeskTop)}>
                              {tagRegex.test(eventDetails) ? (
                                <div
                                  className={classNames(css.eventDescription, {
                                    [css.fullDet]: isFullDetails,
                                  })}
                                  dangerouslySetInnerHTML={{
                                    __html: eventDetails,
                                  }}
                                ></div>
                              ) : (
                                <div
                                  className={classNames(css.eventDescription, {
                                    [css.fullDet]: isFullDetails,
                                  })}
                                >
                                  {eventDetails}
                                </div>
                              )}
                              {showReadmoreBtn ? (
                                <div className={css.eventReadButton} onClick={onReadMoreClick}>
                                  {readmoreBtnTxt}
                                </div>
                              ) : null}
                              <div className={css.locationContainer}>
                                <div className={css.locationInidividual}>
                                  <div>
                                    {m?.eventOnlineCheckBox?.length > 0 && (
                                      <OnlineWorkout className={css.workout} />
                                    )}
                                  </div>
                                  <div>
                                    {m?.eventOfflineCheckBox?.length > 0 && (
                                      <OfflineWorkout className={css.workout} />
                                    )}
                                  </div>
                                </div>
                                {EventLocation && (
                                  <div className={css.locationAddress}>
                                    {hideLocation
                                      ? 'Location to be shared to ticket holders after purchase'
                                      : EventLocation}
                                  </div>
                                )}
                              </div>
                              <div className={css.dateAndTimeContainer}>
                                <div className={css.eventDate}>
                                  <Calender />
                                  {formattedDate}
                                </div>
                                <div className={css.eventDate}>
                                  <Clock />
                                  {m?.eventStartTime?.label.toLowerCase()}-
                                  {m?.eventEndTime?.label.toLowerCase()} : {hours > 0 && hours}{' '}
                                  {hours > 0 && (hours > 1 ? 'hours' : 'hour')}{' '}
                                  {minutes > 0 && minutes}{' '}
                                  {minutes > 0 && (minutes > 1 ? 'mins' : 'min')}
                                  mins
                                </div>
                              </div>
                              <div className={css.mobileBtnWrapper}>
                                <div className={css.priceFormat}>
                                  {modifiedAmount === '£0' ? 'Free' : modifiedAmount}
                                </div>
                                <div className={css.btnWrapper}>
                                  <button
                                    type="button"
                                    className={classNames(css.bookBtn, css.eventBookBtn)}
                                    disabled={!!isOwnListing || !remainingSeats}
                                    onClick={() => {
                                      if (!remainingSeats) return;
                                      const bookingType =
                                        m.eventOfflineCheckBox?.[0] && m.eventOnlineCheckBox?.[0]
                                          ? null
                                          : m.eventOfflineCheckBox?.[0] ||
                                            m.eventOnlineCheckBox?.[0];
                                      setEventBookingDetailsState({
                                        ...m,
                                        remainingSeats,
                                        guests: 1,
                                        bookingType,
                                      });
                                      setBookEventModal(true);
                                    }}
                                  >
                                    Buy Ticket
                                  </button>
                                  <div className={css.availableTickets}>
                                    {remainingSeats} tickets available
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                </div>
              </>
            )}

          <SliderFaqComponent currentListing={currentListing} sliderDefaultCount={4} />
          <div className={css.service}>Ratings & Reviews:</div>
          <div className={css.Line}></div>
          {reviews?.length > 0 ? (
            <div>
              <Slider {...settings}>
                {reviews?.map((review, indx) => {
                  return (
                    <div className={css.reviewCard}>
                      <div className={css.reviewContents}>
                        <div className={css.information}>
                          <div className={css.img}>
                            <AvatarSmall
                              className={css.avatar}
                              user={review.author}
                              disableProfileLink
                            />
                          </div>
                          <div className={css.reviewinfoDiv}>
                            <p>{review.author.attributes.profile.displayName}</p>
                            <p>
                              Fleggs user since{' '}
                              {moment(review?.attributes?.createdAt).format('YYYY')}
                            </p>
                          </div>
                        </div>
                        <div className={css.reviewDiv}>
                          <p className={css.reviewTitle}>{review.attributes.content}</p>
                          {/* <p className={css.reviewDesc}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                          commodo consequat.
                        </p> */}
                        </div>
                      </div>
                      <div className={css.ratingDiv}>
                        <p>{review.attributes.rating}/5</p>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          ) : (
            <div className={css.noReview}>No Reviews</div>
          )}
          {/* <div className={css.reviewCard}>
            <div className={css.reviewContents}>
              <div className={css.information}>
                <div className={css.img}>
                  <AvatarSmall className={css.avatar} user={currentAuthor} disableProfileLink />
                </div>
                <div className={css.reviewinfoDiv}>
                  <p>John Doe</p>
                  <p>Fleggs user since 2023</p>
                </div>
              </div>
              <div className={css.reviewDiv}>
                <p className={css.reviewTitle}>“One of the best yoga sessions Ive ever had!”</p>
                <p className={css.reviewDesc}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className={css.ratingDiv}>
              <p>5/5</p>
            </div>
          </div> */}
          {/* <div className={css.mobileReviewDiv}>
            <p className={css.reviewTitle}>“One of the best yoga sessions Ive ever had!”</p>
            <p className={css.reviewDesc}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p>
          </div> */}
        </div>
        <Modal
          onManageDisableScrolling={onManageDisableScrolling}
          isOpen={bookEventModal}
          className={css.bookingModal}
          onClose={e => {
            setEventBookingDetailsState({});
            setBookEventModal(false);
          }}
          id={'bookEventModal'}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          usePortal
        >
          {eventBookingDetailsState?.eventId && (
            <FinalForm
              groupArr={groupArr}
              onSubmit={eventData => {
                const {
                  eventEndTime,
                  eventStartTime,
                  eventStartDate,
                  eventId,
                  eventPrice,
                  eventTitle,
                  guests,
                  bookingType,
                } = eventData;
                if (!guests || !bookingType) {
                  return;
                }
                const {
                  value: { hour: startHour, minute: startMinute },
                } = eventStartTime;
                const {
                  value: { hour: endHour, minute: endMinute },
                } = eventEndTime;
                const bookingStartDate = moment(eventStartDate)
                  .startOf('day')
                  .add(startHour, 'hours')
                  .add(startMinute, 'minutes')
                  .toDate();
                const bookingStartTime = moment(eventStartDate)
                  .startOf('day')
                  .add(startHour, 'hours')
                  .add(startMinute, 'minutes')
                  .valueOf();
                const bookingEndDate = moment(eventStartDate)
                  .startOf('day')
                  .add(endHour, 'hours')
                  .add(endMinute, 'minutes')
                  // .subtract(1, 'second')
                  .toDate();
                const bookingEndTime = moment(eventStartDate)
                  .startOf('day')
                  .add(endHour, 'hours')
                  .add(endMinute, 'minutes')
                  // .subtract(1, 'second')
                  .valueOf();
                const submitParams = {
                  guests,
                  seats: guests,
                  serviceName: 'event',
                  serviceId: eventId,
                  bookingType,
                  bookingStartDate: {
                    date: bookingStartDate,
                  },
                  bookingStartTime,
                  bookingEndDate: {
                    date: bookingEndDate,
                  },
                  bookingEndTime,
                  isFree: eventPrice?.amount === 0,
                  eventName: eventTitle,
                };
                handleOrderSubmit(submitParams);
              }}
              initialValues={eventBookingDetailsState}
              render={renderProps => {
                const { initialValues, handleSubmit, values, groupArr, form } = renderProps;

                const {
                  eventStartDate,
                  eventStartTime,
                  eventEndTime,
                  eventPrice,
                  eventOfflineCheckBox,
                  eventOnlineCheckBox,
                  eventLocation,
                  eventTitle,
                  remainingSeats,
                  customEventLocation,
                  locationPrivateCheckBox,
                } = initialValues;
                const sessionTypeArr = getSessionType(
                  {
                    eventOfflineCheckBox,
                    eventOnlineCheckBox,
                    eventLocation,
                    customEventLocation,
                    locationPrivateCheckBox,
                  },
                  intl
                );
                const formattedDate = moment(eventStartDate).format('dddd, DD MMM, YYYY');
                const formattedStartTime = `${eventStartTime?.label} - ${eventEndTime?.label}`;
                const filteredGroupArr = groupArr.slice(0, 1);
                filteredGroupArr.push({
                  label: 'Group (Coming Soon)',
                  value: 'group',
                });
                const priceAsMoney = eventPrice.amount
                  ? new Money(eventPrice.amount * values.guests, eventPrice.currency)
                  : null;
                const price =
                  priceAsMoney instanceof Money ? formatMoney(intl, priceAsMoney) : 'FREE';
                const submitDisabled = !(values.guests && values.bookingType);
                return (
                  <Form className={css.eventbookingform} onSubmit={handleSubmit}>
                    <div className={css.orderHeading}>
                      <H4 as="h1" className={css.orderPanelTitle}>
                        <FormattedMessage
                          id="ListingPage.orderTitle"
                          values={{
                            title: (
                              <span>
                                {richText(eventTitle, {
                                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
                                  longWordClass: css.longWord,
                                })}
                              </span>
                            ),
                          }}
                        />
                      </H4>
                      <p className={css.eventModalPrice}>{price}</p>
                    </div>
                    <div className={css.eventbookingformchild}>
                      <span>Date: </span>{' '}
                      <span>
                        <MdOutlineCalendarMonth className={css.calIcn} />
                        {formattedDate}
                      </span>
                    </div>
                    <div className={css.eventbookingformchild}>
                      <span>Time: </span>{' '}
                      <span>
                        <TbClockHour9 className={css.clckIcn} />
                        {formattedStartTime}
                      </span>
                    </div>

                    <div>
                      <FieldCustomSelect
                        name="bookingType"
                        label={intl.formatMessage({
                          id: 'BookingTimeForm.selectBookingType',
                        })}
                        id="bookingType"
                        closeOnSelect={true}
                        className={css.opions}
                      >
                        {sessionTypeArr.map((ar, indx) => (
                          <SelectOption
                            key={`${eventTitle}-${indx}`}
                            value={ar.value}
                            hidden={indx == 0 && sessionTypeArr.length > 1}
                          >
                            {ar.label}
                          </SelectOption>
                        ))}
                      </FieldCustomSelect>
                    </div>
                    {values?.bookingType === 'online' ? (
                      <div className={css.eventbookingformchild}>
                        <span>Ticket: </span>
                        <span>1</span>
                      </div>
                    ) : values?.bookingType === 'offline' ? (
                      <div className={css.eventbookingformchild}>
                        <span>Guest(s):</span>
                        <SeatBox
                          maxSeats={remainingSeats}
                          onSeatsUpdate={seats => form.change('guests', seats)}
                          rootClassName={css.listingPageSeatBox}
                          showMaxSeats={true}
                        />
                      </div>
                    ) : null}
                    {/* <FieldCustomSelect
                        name="guests"
                        label={intl.formatMessage({ id: 'BookingTimeForm.bookingGuestTitle' })}
                        id="guests"
                        className={css.opions}
                        closeOnSelect={true}
                        disabled={true}
                      >
                        {filteredGroupArr.map((ar, indx) => (
                          <SelectOption
                            key={`${eventTitle}-${indx}`}
                            value={ar.value}
                            disabled={ar.value === 'group' ? true : false}
                            // disabled={indx > 0 && true}
                            // hidden={true}
                          >
                            {ar.label}
                          </SelectOption>
                        ))}
                      </FieldCustomSelect> */}

                    <PrimaryButton disabled={submitDisabled}>Book event</PrimaryButton>
                  </Form>
                );
              }}
            />
          )}
        </Modal>
        <div className={css.sliderContainer}>
          <ModalImageCarousal
            isOpen={isOpen}
            onManageDisableScrolling={onManageDisableScrolling}
            imageLinks={imageLinks}
            setIsOpen={setIsOpen}
            handleHover={handleHover}
            handleMouseLeave={handleMouseLeave}
            videoRef={videoRef}
            toggleMute={toggleMute}
            ismuted={ismuted}
          />
          {/* <Modal
            className={css.ContactUsmodal}
            id="imageModal"
            isOpen={isOpen}
            scrollLayerClassName={css.carouselModalScrollLayer}
            containerClassName={css.carouselModalContainer}
            onClose={() => {
              setIsOpen(false);
              setInitialSlide(0);
            }}
            // showAsModalMaxWidth={MODAL_BREAKPOINT}
            onManageDisableScrolling={onManageDisableScrolling}
            usePortal
          >
            <div>
              <Slider {...settings}>
                {imageLinks?.map((m, i) => (
                  // console.log(905, imageLinks);
                  <div className={css.parentContainer}>
                    {m?.location?.includes('/images/') ? (
                      <div className={css.imageContainer}>
                        <img src={m?.location} alt="Preview" className={css.sliderImageWrapper} />
                      </div>
                    ) : m?.location?.includes('/videos/') ? (
                      <div
                        className={css.imageContainer}
                        onMouseEnter={handleHover}
                        onMouseLeave={handleMouseLeave}
                      >
                        <video ref={videoRef} className={css.sliderImageWrapper} muted="muted">
                          <source src={m?.location} type="video/mp4" />
                        </video>
                      </div>
                    ) : null}
                  </div>
                ))}
              </Slider>
            </div>
          </Modal> */}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    eventSlots,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };
  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    eventSlots,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedListingPage);

export default ListingPage;
