import React, { useRef, useState } from 'react';
import css from './SliderFaqComponent.module.css';
import { BiVolumeMute } from 'react-icons/bi';
import { GoUnmute } from 'react-icons/go';
import classNames from 'classnames';

const SliderEach = props => {
  const { slide, indx } = props;

  const [ismuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const handleHover = () => {
    videoRef?.current?.play();
  };

  const toggleMute = e => {
    e.preventDefault();
    // e.stopPropagation();
    if (videoRef.current.muted) {
      videoRef.current.muted = false;
      setIsMuted(false);
    } else {
      videoRef.current.muted = true;
      setIsMuted(true);
    }
  };

  const handleMouseLeave = () => {
    videoRef?.current?.pause();
    if (videoRef && videoRef.current && videoRef.current.currentTime) {
      videoRef.current.currentTime = 0;
    }
  };
  return (
    <>
      <div key={indx} className={css.sliderContainer}>
        {slide?.key?.split('/')?.[0] === 'images' || slide?.key?.split('/')?.[0] === 'videos' ? (
          <>
            {slide?.key?.split('/')?.[0] === 'images' ? (
              <div className={css.imageContainer}>
                <img src={slide?.location} className={css.previewImage} key={indx} />
              </div>
            ) : slide?.key?.split('/')?.[0] === 'videos' ? (
              <>
                <div
                  className={css.imageContainer}
                  onMouseEnter={handleHover}
                  onMouseLeave={handleMouseLeave}
                >
                  <video ref={videoRef} className={css.previewImage} muted="muted">
                    <source src={slide?.location} type="video/mp4" />
                  </video>
                </div>
                <div
                  className={css.muteButton}
                  onClick={e => toggleMute(e)}
                  onMouseEnter={handleHover}
                  onMouseLeave={handleMouseLeave}
                >
                  {ismuted ? <BiVolumeMute /> : <GoUnmute />}
                </div>
              </>
            ) : null}
          </>
        ) : (
          <div
            className={classNames(css.motivationalQuote, {
              [css.evenColor]: slide?.index % 2 === 0,
            })}
          >
            <p>{slide?.question}</p>

            <div>{slide?.answer}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default SliderEach;
