import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as CustomNextArrow } from '../../assets/rightArrow.svg';
import { ReactComponent as CustomPrevArrow } from '../../assets/leftArrow.svg';
import css from './SliderFaqComponent.module.css';
import classNames from 'classnames';
import Slider from 'react-slick';
import SliderEach from './SliderEach';

const SliderFaqComponent = props => {
  const { currentListing, sliderDefaultCount } = props;
  const {
    personal,
    servicesList,
    quote,
    philosophy,
    workout,
    fitnessTip,
    fitness,
    motivate,
    greenFlag,
    typicalSunday,
    additionalImageOrVedios,
  } = currentListing?.attributes?.publicData || '';
  const [slideArray, setSlideArray] = useState([]);
  const settings = {
    dots: false,
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: sliderDefaultCount,
    slidesToScroll: sliderDefaultCount,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    arrows: slideArray?.length > 4 ? true : false,
    initialSlide: 0,
    // variableWidth: true,
    // centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: slideArray?.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
  const faqs = [
    { question: 'Working out with me is like:', answer: quote },
    { question: "I'm a regular at:", answer: philosophy },
    { question: 'A life goal of mine:', answer: workout },
    { question: 'Give me fitness hangouts for:', answer: fitnessTip },
    { question: 'My most irrational fear:', answer: fitness },
    { question: 'What motivates me:', answer: motivate },
    { question: 'My green flags:', answer: greenFlag },
    { question: 'Typical Sunday:', answer: typicalSunday },
  ];
  const updatedFaqs = faqs.filter(f => f.answer !== undefined);
  updatedFaqs.forEach((faq, index) => {
    faq.index = index + 1;
  });
  const cAdditionalImageOrVedios = additionalImageOrVedios && [...additionalImageOrVedios];
  const cUpdatedFaqs = [...updatedFaqs];
  useEffect(() => {
    for (let i = 0; i < additionalImageOrVedios?.length && i < updatedFaqs?.length; i++) {
      if (slideArray?.length <= i) {
        setSlideArray(p => [...p, additionalImageOrVedios?.[i]]);
        cAdditionalImageOrVedios.shift();
      }
      if (slideArray?.length <= i) {
        setSlideArray(p => [...p, updatedFaqs?.[i]]);
        cUpdatedFaqs.shift();
      }
    }
    if (cAdditionalImageOrVedios?.length > 0) {
      for (let i = slideArray?.length; i < cAdditionalImageOrVedios?.length; i++) {
        if (slideArray?.length <= i) setSlideArray(p => [...p, cAdditionalImageOrVedios?.[i]]);
      }
    } else if (cUpdatedFaqs?.length > 0) {
      for (let i = slideArray?.length; i < cUpdatedFaqs?.length; i++) {
        if (slideArray?.length <= i) setSlideArray(p => [...p, cUpdatedFaqs?.[i]]);
      }
    }
  }, [additionalImageOrVedios]);
  const imageDivClassName =
    slideArray?.length < 4 ? classNames(css.imgDescDiv, css.imgDescDivTransform) : css.imgDescDiv;
  return (
    <>
      <div className={`${imageDivClassName} communityFavouritesForOther`}>
        <Slider {...settings}>
          {slideArray?.map((slide, indx) => {
            return (
              // <div>
              <SliderEach slide={slide} indx={indx} key={indx} />
              // </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default SliderFaqComponent;
